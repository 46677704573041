@import "~bootstrap-icons/font/bootstrap-icons";
@import "./scss/style.scss";

.n-lines-1 {
        -webkit-line-clamp: 1;
        overflow : hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
}

.n-lines-2 {
        -webkit-line-clamp: 2;
        overflow : hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
}

.n-lines-3 {
        -webkit-line-clamp: 3;
        overflow : hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
}

.n-lines-4 {
        -webkit-line-clamp: 4;
        overflow : hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
}

.n-lines-5 {
        -webkit-line-clamp: 5;
        overflow : hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
}
      

.gradient {
        position: relative;
        bottom: 8em;
        height: 8em;
        background: -webkit-linear-gradient(rgba(255, 255, 255, 0) 0%,
                        rgba(255, 255, 255, 1) 100%);
        background-image: -moz-linear-gradient(rgba(255, 255, 255, 0) 0%,
                        rgba(255, 255, 255, 1) 100%);
        background-image: -o-linear-gradient(rgba(255, 255, 255, 0) 0%,
                        rgba(255, 255, 255, 1) 100%);
        background-image: linear-gradient(rgba(255, 255, 255, 0) 0%,
                        rgba(255, 255, 255, 1) 100%);
        background-image: -ms-linear-gradient(rgba(255, 255, 255, 0) 0%,
                        rgba(255, 255, 255, 1) 100%);
}

.inline-icon {
        width: 1em;
        height: 1em;
        display: inline-block !important;
        vertical-align: -0.125em !important;
}

.dashboard-content {
        min-height: 100%;
}

.sidebar {
        /* added by me */
        padding-left: 0px !important;
        padding-right: 0px !important;

        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        z-index: 100;
        /* Behind the navbar */
}

.sidebar-sticky {
        margin-top: 0 !important;
        /* Height of navbar */
        position: relative;
        top: 0;
        height: calc(100vh);
        overflow-x: hidden;
        overflow-y: scroll;
        /* has to be scroll, not auto */
        -webkit-overflow-scrolling: touch;
}

@include media-breakpoint-down(md) {
        .sidebar-sticky {
                min-width: 275px;
                max-width: 275px;
        }
}

@include media-breakpoint-only(lg) {
        .sidebar-sticky {
                min-width: 90px;
                max-width: 90px;
        }
}

@include media-breakpoint-up(xl) {
        .sidebar-sticky {
                min-width: 90px;
                max-width: 275px;
        }
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
        .sidebar-sticky {
                position: -webkit-sticky;
                position: sticky;
        }
}

.page-wrapper {
        flex: 1 0 auto;
}

@media screen {
        .bg-repeat-0 {
                background-repeat: no-repeat !important;
        }
}

@media screen {
        .bg-position-center {
                background-position: center !important;
        }
}

@media screen {
        .bg-size-cover {
                background-size: cover !important;
        }
}

@media screen {
        @media (min-width: 992px) {
                .w-lg-50 {
                        width: 50% !important;
                }
        }
}

.dropdown-toggle::after {
        display: none;
}

#delete-modal {
        max-width: 380px;
}

.cal-month,
.cal-days,
.cal-weekdays {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        align-items: center;
}

.cal-month-name {
        grid-column-start: 2;
        grid-column-end: 7;
        text-align: center;
}

.cal-weekday,
.cal-btn {
        display: flex;
        flex-shrink: 0;
        align-items: center;
        justify-content: center;
        height: 3rem;
        padding: 0;
}

.cal-btn:not([disabled]) {
        font-weight: 500;
}

.cal-btn:hover,
.cal-btn:focus {
        background-color: rgba(0, 0, 0, .05);
}

.cal-btn[disabled] {
        border: 0;
        opacity: .5;
}

// The dropdown menu
.dropdown-menu {
        // scss-docs-start dropdown-css-vars
        --#{$prefix}dropdown-zindex: #{$zindex-dropdown};
        --#{$prefix}dropdown-min-width: #{$dropdown-min-width};
        --#{$prefix}dropdown-padding-x: #{$dropdown-padding-x};
        --#{$prefix}dropdown-padding-y: #{$dropdown-padding-y};
        --#{$prefix}dropdown-spacer: #{$dropdown-spacer};
        @include rfs($dropdown-font-size, --#{$prefix}dropdown-font-size);
        --#{$prefix}dropdown-color: #{$dropdown-color};
        --#{$prefix}dropdown-bg: #{$dropdown-bg};
        --#{$prefix}dropdown-border-color: #{$dropdown-border-color};
        --#{$prefix}dropdown-border-radius: #{$dropdown-border-radius};
        --#{$prefix}dropdown-border-width: #{$dropdown-border-width};
        --#{$prefix}dropdown-inner-border-radius: #{$dropdown-inner-border-radius};
        --#{$prefix}dropdown-divider-bg: #{$dropdown-divider-bg};
        --#{$prefix}dropdown-divider-margin-y: #{$dropdown-divider-margin-y};
        --#{$prefix}dropdown-box-shadow: #{$dropdown-box-shadow};
        --#{$prefix}dropdown-link-color: #{$dropdown-link-color};
        --#{$prefix}dropdown-link-hover-color: #{$dropdown-link-hover-color};
        --#{$prefix}dropdown-link-hover-bg: #{$dropdown-link-hover-bg};
        --#{$prefix}dropdown-link-active-color: #{$dropdown-link-active-color};
        --#{$prefix}dropdown-link-active-bg: #{$dropdown-link-active-bg};
        --#{$prefix}dropdown-link-disabled-color: #{$dropdown-link-disabled-color};
        --#{$prefix}dropdown-item-padding-x: #{$dropdown-item-padding-x};
        --#{$prefix}dropdown-item-padding-y: #{$dropdown-item-padding-y};
        --#{$prefix}dropdown-header-color: #{$dropdown-header-color};
        --#{$prefix}dropdown-header-padding-x: #{$dropdown-header-padding-x};
        --#{$prefix}dropdown-header-padding-y: #{$dropdown-header-padding-y};
        // scss-docs-end dropdown-css-vars

        position: absolute;
        z-index: var(--#{$prefix}dropdown-zindex);
        display: none; // none by default, but block on "open" of the menu
        min-width: var(--#{$prefix}dropdown-min-width);
        padding: var(--#{$prefix}dropdown-padding-y) var(--#{$prefix}dropdown-padding-x);
        margin: 0; // Override default margin of ul
        @include font-size(var(--#{$prefix}dropdown-font-size));
        color: var(--#{$prefix}dropdown-color);
        text-align: left; // Ensures proper alignment if parent has it changed (e.g., modal footer)
        list-style: none;
        background-color: var(--#{$prefix}dropdown-bg);
        background-clip: padding-box;
        border: var(--#{$prefix}dropdown-border-width) solid var(--#{$prefix}dropdown-border-color);
        @include border-radius(var(--#{$prefix}dropdown-border-radius));
        @include box-shadow(var(--#{$prefix}dropdown-box-shadow));

        &[data-bs-popper] {
                top: 100%;
                left: 0;
                margin-top: var(--#{$prefix}dropdown-spacer);
        }

        @if $dropdown-padding-y ==0 {

                >.dropdown-item:first-child,
                >li:first-child .dropdown-item {
                        @include border-top-radius(var(--#{$prefix}dropdown-inner-border-radius));
                }

                >.dropdown-item:last-child,
                >li:last-child .dropdown-item {
                        @include border-bottom-radius(var(--#{$prefix}dropdown-inner-border-radius));
                }

        }
}

#dashboard-body {
        margin-bottom: 0;
        max-width: 650px;
}
@include media-breakpoint-down(lg) {
        #dashboard-body {
                margin-bottom: $btn-icon-size-sm;
                max-width: 650px;
        }
}

.border-dashed {
        border: var(--ar-border-width) dashed #d6dfe8 !important;
}

#root {
        height: 100%;
}
.App {
        height: 100%;
}

@include media-breakpoint-down(md) {
        #root {
                height: 100%;
        }
        .App {
                height: 100%;
        }
}